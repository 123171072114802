<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      title="List des dossiers"
      v-b-modal.list-dossier-simulation
      @click.prevent.stop="handleCheckDossier"
    >
      <font-awesome-icon icon="list" />
    </b-button>
    <b-modal
      ref="list-dossier-simulation"
      id="list-dossier-simulation"
      no-close-on-backdrop
      :hide-footer="true"
      :size="'lg'"
      :title="'Confirmation des dossiers '"
      modal-class="modal-extaction-bdd-frais"
    >
      <template>
        <div>
          <div class="row">
            <div class="col-6">
              <div>
                <div class="d-flex justify-content-start text-dark mt-1">
                  <p><b>Liste des dossiers confirmée :</b></p>
                </div>
                <div
                  v-if="computedListDossierConfirme.length == 0"
                  class="justify-content-center"
                >
                  Aucun dossier
                </div>
                <template>
                  <div class="d-flex justify-content-between">
                    <b-form-checkbox
                      v-model="checkAllConfirme"
                      switch
                      @change="changeAllDossierConfirme"
                      >Tous</b-form-checkbox
                    >
                    <b-form-group
                      label=""
                      label-for="search-add"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="searchConfirme"
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <br />
                  <b-form-group label="" class="d-block">
                    <div class="custom-class-fields-av scroll-specifics-params">
                      <b-form-checkbox
                        v-for="(option, index) in computedListDossierConfirme"
                        :key="
                          option.confirmations == 0
                            ? index + 'deconf' + option.id
                            : index + 'conf' + option.is
                        "
                        v-model="selectedConfirme"
                        :value="option"
                      >
                        {{ option.numero_dossier }}
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                  <b-button
                    size="sm"
                    class="button-export-style  ml-2"
                    title="Déconfimer"
                    @click="changeConfirmer(1)"
                  >
                    Déconfirmée <font-awesome-icon icon="angle-right" />
                  </b-button>
                </template>
              </div>
            </div>
            <div class="col-6">
              <div>
                <div class="d-flex justify-content-start text-dark mt-1">
                  <p><b>Liste des dossiers non confirmée :</b></p>
                </div>
                <div
                  v-if="computedListDossierNonConfirme.length == 0"
                  class="justify-content-start"
                >
                  Aucun dossier
                </div>
                <template>
                  <div class="d-flex justify-content-between">
                    <b-form-checkbox
                      v-model="checkAllNonConfirme"
                      switch
                      @change="changeAllDossierNonConfirme"
                      >Tous</b-form-checkbox
                    >
                    <b-form-group
                      label=""
                      label-for="search-add"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="searchNonConfirme"
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <br />
                  <b-form-group label="" class="d-block">
                    <div class="custom-class-fields-av scroll-specifics-params">
                      <b-form-checkbox
                        v-for="(option,
                        index) in computedListDossierNonConfirme"
                        :key="
                          option.confirmations == 0
                            ? index + 'deconf' + option.id
                            : index + 'conf' + option.is
                        "
                        v-model="selectedNonConfirme"
                        :value="option"
                      >
                        {{ option.numero_dossier }}
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                  <b-button
                    size="sm"
                    class="button-export-style  ml-2"
                    title="Confimer"
                    @click="changeConfirmer(2)"
                  >
                    <font-awesome-icon icon="angle-left" />
                    Confirmée
                  </b-button>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-modal-custom-style mt-2">
          <div class="messageError text-aligne-center">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>
          <div class="actionModel">
            <b-button class="button-valide-style " @click="changeStatut()">
              <span>
                Générer
                <div v-if="loading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div></div
      > -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['dataToUse'],
  data() {
    return {
      searchConfirme: null,
      searchNonConfirme: null,
      checkAllConfirme: false,
      checkAllNonConfirme: false,
      selectedConfirme: [],
      selectedNonConfirme: []
    };
  },
  methods: {
    ...mapActions(['confirmationMultipleDossierSimulation']),
    handleCheckDossier() {
      this.selectedConfirme = [];
      this.selectedNonConfirme = [];
      this.dataToUse.forEach(element => {
        if (element.project_confirmation === true) {
          this.selectedConfirme.push({
            id: element.project_id,
            numero_dossier: element.project_numero,
            confirmations: 1
          });
        } else {
          this.selectedNonConfirme.push({
            id: element.project_id,
            numero_dossier: element.project_numero,
            confirmations: 0
          });
        }
      });
    },
    changeAllDossierConfirme() {
      this.selectedConfirme = this.checkAllConfirme
        ? this.computedListDossierConfirme.slice()
        : [];
    },
    changeAllDossierNonConfirme() {
      this.selectedNonConfirme = this.checkAllNonConfirme
        ? this.computedListDossierNonConfirme.slice()
        : [];
    },
    async changeConfirmer(e) {
      var bodyFormData = new FormData();
      if (e == 1) {
        for (let j = 0; j < this.selectedConfirme.length; j++) {
          bodyFormData.append('ids[' + [j] + ']', this.selectedConfirme[j].id);
        }
      } else {
        for (let j = 0; j < this.selectedNonConfirme.length; j++) {
          bodyFormData.append(
            'ids[' + [j] + ']',
            this.selectedNonConfirme[j].id
          );
        }
      }

      let testValue = null;
      for (var pair of bodyFormData.entries()) {
        if (pair[0] && pair[0].includes('ids')) {
          testValue = true;
        }
      }
      if (testValue == null) {
        this.error =
          "Sélectionne au moins un dossier afin d'effectuer un changement de l'etat.";
        this.$alert('', this.error, 'error').then(() => {
          this.error = null;
        });

        return;
      } else {
        const response = await this.confirmationMultipleDossierSimulation(
          bodyFormData
        );
        if (response && response.succes == true) {
          response.data.forEach(element => {
            let index = this.getSimulationFactureFraisDataDossier.findIndex(
              i => i.id == element.id
            );
            if (index >= 0) {
              this.getSimulationFactureFraisDataDossier[index].confirmations =
                element.confirmations == true ? 1 : 0;
            }
            let indexes = [];
            for (
              let indexP = 0;
              indexP < this.getSimulationFactureFraisData.length;
              indexP++
            ) {
              if (
                this.getSimulationFactureFraisData[indexP].project_id ==
                element.id
              ) {
                indexes.push(indexP);
              }
            }

            indexes.forEach(element1 => {
              this.getSimulationFactureFraisData[
                element1
              ].project_confirmation = element.confirmations;
            });
          });
          this.selectedConfirme = [];
          this.selectedNonConfirme = [];
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getSimulationFactureFraisDataDossier',
      'getSimulationFactureFraisData'
    ]),
    computedListDossierConfirme() {
      if (this.searchConfirme != null) {
        return this.getSimulationFactureFraisDataDossier.filter(
          item =>
            item.numero_dossier
              .toLowerCase()
              .includes(this.searchConfirme.toLowerCase()) &&
            item.confirmations == 1
        );
      }
      return this.getSimulationFactureFraisDataDossier.filter(
        item => item.confirmations == 1
      );
    },
    computedListDossierNonConfirme() {
      if (this.searchNonConfirme != null) {
        return this.getSimulationFactureFraisDataDossier.filter(
          item =>
            item.numero_dossier
              .toLowerCase()
              .includes(this.searchNonConfirme.toLowerCase()) &&
            item.confirmations == 0
        );
      }
      return this.getSimulationFactureFraisDataDossier.filter(
        item => item.confirmations == 0
      );
    }
  }
};
</script>

<style lang="scss">
.custom-class-fields-av,
.custom-class-fields-av-update {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow-x: auto;
  text-align-last: left;
  max-height: calc(100% - 90px);
}

.custom-class-fields-av {
  .custom-control {
    width: 30%;
  }
}
.scroll-specifics-params {
  height: calc(100vh - 266px);
  overflow-y: auto;
  align-content: start;
  align-items: flex-start;
}
.scroll-specifics-params::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.scroll-specifics-params::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.scroll-specifics-params::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}
</style>
